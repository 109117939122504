$primary: #146367; 	
    
h4 {
  font-size:  30px; 
  font-weight:  600; 
  color:  #000; 
  opacity:  .85; 
}
     
.log-in {
  padding:  40px 20px; 
  background:  #fefefe; 
  width: 390px;
  height: auto;
  display:flex; 
  flex-direction:  column;
  justify-content: center;
  margin: auto; 
  padding-bottom:  22px; 

  h4 {
    color:  rgba(#000, .5);
    margin-right: 90px;
    
    span {
      color:  rgba(#000, 1);
      font-weight:  700;
      width: 100%;
    }
  }
  
  p {
    margin-right: 75px;
    line-height:  155%; 
    font-size:  14px; 
    color:  #000; 
    opacity:  .65;
    font-weight:  400; 
  }
  
  hr {
    width: 275px;
  }
}

a.discrete {
  color:  rgba(#000, .4); 
  font-size:  14px; 
  border-bottom:  solid 1px rgba(#000, .0);
  padding-bottom:  4px;  
  margin-left:  auto; 
  font-weight:  300; 
  transition:  all .3s ease; 
  margin-top:  40px; 

  &:hover {
    border-bottom:  solid 1px rgba(#000, .2);
  }
}
    
input {
  font-size:  16px; 
  padding:  20px 0px; 
  height:  56px; 
  border:  none; 
  border-bottom:  solid 1px rgba(0,0,0,.1); 
  background:  #fff; 
  min-width:  345px; 
  box-sizing:  border-box; 
  transition:  all .3s linear; 
  color:  #000; 
  font-weight:  400; 
  -webkit-appearance:  none;   

  &:focus {
    border-bottom:  solid 1px $primary; 
    outline: 0; 
    box-shadow:  0 2px 6px -8px rgba($primary, .45);
  }
}

.floating-label {
  position:  relative; 
  margin-bottom:  10px; 

  label {
    position:  absolute; 
    top: calc(50% - 7px);
    left:  0; 
    opacity:  0; 
    transition:  all .3s ease; 
  }
  
  input:not(:placeholder-shown) {
    padding:  28px 0px 12px 0px; 
  }
    
  input:not(:placeholder-shown) + label {
    transform:  translateY(-10px); 
    opacity:  .7; 
  }
}
    
.session {
  display:  flex; 
  flex-direction:  row; 
  width:  auto; 
  height:  auto; 
  margin:  auto auto; 
  background:  #ffffff; 
  border-radius:  4px; 
  box-shadow:  0px 2px 6px -1px rgba(0,0,0,.12);
}

.bg-left {
  min-height: 100vh;
  background-image: url("../assets/bg-Abzen/abzen.png");
  background-position: top center;
  background-size:cover;
  background-repeat: no-repeat;
}

.loginx {
  font-size: 50px;
  font-weight: 0;
  margin: 50px 40px 40px;
}

.eulax {
  color: #fff;
  margin: 0px 50px;
  margin-top: 150px;
}

.MuiContainer-root{
  max-width: 100% !important;
  padding: 0px !important;
}
// body {
//     color: #000;
//     overflow-x: hidden;
//     height: 100%;
//     background-color: #B0BEC5;
//     background-repeat: no-repeat
// }

// // .card0 {
// //     box-shadow: 0px 4px 8px 0px #757575;
// //     border-radius: 0px
// // }

// // .card2 {
// //     margin: 0px 40px
// // }

// // .logo {
// //     width: 200px;
// //     height: 100px;
// //     margin-top: 20px;
// //     margin-left: 35px
// // }

// // .image {
// //     width: 360px;
// //     height: 280px
// // }

// // .border-line {
// //     border-right: 1px solid #EEEEEE
// // }

// // .facebook {
// //     background-color: #3b5998;
// //     color: #fff;
// //     font-size: 18px;
// //     padding-top: 5px;
// //     border-radius: 50%;
// //     width: 35px;
// //     height: 35px;
// //     cursor: pointer
// // }

// // .twitter {
// //     background-color: #1DA1F2;
// //     color: #fff;
// //     font-size: 18px;
// //     padding-top: 5px;
// //     border-radius: 50%;
// //     width: 35px;
// //     height: 35px;
// //     cursor: pointer
// // }

// // .linkedin {
// //     background-color: #2867B2;
// //     color: #fff;
// //     font-size: 18px;
// //     padding-top: 5px;
// //     border-radius: 50%;
// //     width: 35px;
// //     height: 35px;
// //     cursor: pointer
// // }

// // .line {
// //     height: 1px;
// //     width: 45%;
// //     background-color: #E0E0E0;
// //     margin-top: 10px
// // }

// // .or {
// //     width: 10%;
// //     font-weight: bold
// // }

// // .text-sm {
// //     font-size: 14px !important
// // }

// // ::placeholder {
// //     color: #BDBDBD;
// //     opacity: 1;
// //     font-weight: 300
// // }

// // :-ms-input-placeholder {
// //     color: #BDBDBD;
// //     font-weight: 300
// // }

// // ::-ms-input-placeholder {
// //     color: #BDBDBD;
// //     font-weight: 300
// // }

// // input,
// // textarea {
// //     padding: 10px 12px 10px 12px;
// //     border: 1px solid lightgrey;
// //     border-radius: 2px;
// //     margin-bottom: 5px;
// //     margin-top: 2px;
// //     width: 100%;
// //     box-sizing: border-box;
// //     color: #2C3E50;
// //     font-size: 14px;
// //     letter-spacing: 1px
// // }

// // input:focus,
// // textarea:focus {
// //     -moz-box-shadow: none !important;
// //     -webkit-box-shadow: none !important;
// //     box-shadow: none !important;
// //     border: 1px solid #304FFE;
// //     outline-width: 0
// // }

// // button:focus {
// //     -moz-box-shadow: none !important;
// //     -webkit-box-shadow: none !important;
// //     box-shadow: none !important;
// //     outline-width: 0
// // }

// // a {
// //     color: inherit;
// //     cursor: pointer
// // }

// // .btn-blue {
// //     background-color: #1A237E;
// //     width: 150px;
// //     color: #fff;
// //     border-radius: 2px
// // }

// // .btn-blue:hover {
// //     background-color: #000;
// //     cursor: pointer
// // }

// // .bg-blue {
// //     color: #fff;
// //     background-color: #1A237E
// // }

// // @media screen and (max-width: 991px) {
// //     .logo {
// //         margin-left: 0px
// //     }

// //     .image {
// //         width: 300px;
// //         height: 220px
// //     }

// //     .border-line {
// //         border-right: none
// //     }

// //     .card2 {
// //         border-top: 1px solid #EEEEEE !important;
// //         margin: 0px 15px
// //     }
// // }
// * {
//     font-family: -apple-system, BlinkMacSystemFont, "San Francisco", Helvetica, Arial, sans-serif;
//     font-weight:  300; 
//     margin:  0px; 
//     padding: 0px ;
//     }
// }
