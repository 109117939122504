.nav-item {
  .nav-link {
    display: flex;
    align-items: center;    

    &.active {
      color: white;
      background: rgba(255, 255, 255, .23);
      opacity: 1;
    }
  }
}

.sidebar .nav a {
  transition: all 150ms ease-in;
}

.card-header {
  text-transform: uppercase;
  border-bottom: 0;
  border-radius: calc(-1px + 0.25rem)!important;
}

.badge:empty {
  display: inline-block;
}

input:focus {
	border-bottom: none;
}

.modal-header{
  border-bottom: none;
}
.modal-header .close {
	padding: 0;
	margin: auto;
	margin-right: 0;
}
.modal-footer{
  border-top: none;
}